import { post } from "@/service/fetcher"
import { SITE_URL } from "@/utils/constants"

export const fetchLogInstallPWA = ({ text }: { text: string }) =>
  post(
    `${SITE_URL}/api/pwa/installed`,
    {
      text,
    },
    false,
    undefined,
    undefined,
    undefined,
  )
