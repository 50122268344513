import { HISTORY_SEARCH_KEY, TRANSLATE_NAME_FACET } from "./constants"
import { SearchHistoryItemType } from "../../store/reducers/searchSlice"

const getHistorySaved = (): SearchHistoryItemType[] => {
  const history = localStorage.getItem(HISTORY_SEARCH_KEY)
  return !!history ? JSON.parse(history) : []
}
const setHistorySaved = (history: SearchHistoryItemType[] | null): void => {
  history !== null
    ? localStorage.setItem(HISTORY_SEARCH_KEY, JSON.stringify(history))
    : localStorage.removeItem(HISTORY_SEARCH_KEY)
}
const translationNameFacet = (name: string): string => {
  return TRANSLATE_NAME_FACET[name] || name
}
const filterToQueryFormat = (
  filter: Record<string, string[]> | null,
): string[] => {
  return Object.entries(filter || {}).map(
    ([name, f]) => `${name}:${f.join(";")}`,
  )
}

const getCleanSearchQuery = ({ query }: { query: string }): string => {
  return query
    .replace(/\s+/g, " ") // заменяет все подряд идущие пробелы на один
    .trim()
}

export {
  getHistorySaved,
  setHistorySaved,
  translationNameFacet,
  filterToQueryFormat,
  getCleanSearchQuery,
}
