export const PRODUCT_IMAGE_DEFAULT_QUALITY = 70
export const PADDING_CONTAINER = {
  grid: {
    topBottom: 20,
    leftRight: 12,
  },
}

export const IMAGE_CONFIG = {
  detail: {
    size: {
      width: 300,
      height: 300,
    },
    quality: 90,
  },
  grid: {
    size: {
      width: 236,
      height: 236,
    },
    quality: PRODUCT_IMAGE_DEFAULT_QUALITY,
  },
  list: {
    size: {
      width: 100,
      height: 100,
    },
    quality: PRODUCT_IMAGE_DEFAULT_QUALITY,
  },
  small: {
    size: {
      width: 60,
      height: 60,
    },
    quality: 40,
  },
  responsive: {
    size: {
      width: 150,
      height: 150,
    },
    quality: PRODUCT_IMAGE_DEFAULT_QUALITY,
  },
}

export const COMPANIONS_TITLE = "Часто покупают вместе"
export const ANALOGS_TITLE = "Похожие товары"

export const CATEGORIES_WITHOUT_LINK_AB = new Map([
  ["konditerka", Boolean],
  ["suhariki", Boolean],
  ["grippery-pakety-s-zamkom", Boolean],
  ["plenka-streych", Boolean],
  ["stiralnyy-poroshok", Boolean],
  ["shokolad", Boolean],
  ["chipsy", Boolean],
])
