import { useMutation } from "react-query"
import { useCallback, useMemo } from "react"
import { fetchShippingCost } from "@/api/checkoutAPI"
import { ShippingMethod } from "@/hooks/shippings/constants"
import { setPaymentMethods, setShippingCost } from "@/store/reducers/cartSlice"
import { getShippingsData } from "./helpers"
import { UseShippingsPropsType, UseShippingsType } from "./types"
import { useAppDispatch, useAppSelector } from "../redux"

export const useShippings: UseShippingsType = (props) => {
  const { product } = props || {}

  const dispatch = useAppDispatch()
  const {
    totalCost,
    paymentMethods,
    shipping: {
      cost: shippingCost,
      type: shippingType,
      free: { minCost: orderMinCost },
    },
  } = useAppSelector(({ cart }) => cart)
  const { shippingMethods, settings, isDateToOnlyCompany } = useAppSelector(
    ({ app }) => app,
  )

  // есть ли в полученных методах доставки - доставка курьером
  const shippingCourierIsExist = shippingMethods?.some(
    (item) => item.alias === ShippingMethod.COURIER,
  )

  const leftFreeShippingCourier: number | null = useMemo(() => {
    // если присутствует вид доставки курьером,
    // значит регион подходящий,
    // значит имеет смысл расчета бесплатной доставки
    if (!shippingCourierIsExist) {
      return null
    }

    if (!orderMinCost) {
      return null
    }
    let diff = 0
    if (orderMinCost > 0) {
      diff = (totalCost - orderMinCost) * -1
    }
    return diff >= 0 ? diff : 0
  }, [orderMinCost, shippingCourierIsExist, totalCost])

  // обертка диспатчер
  const updateShippingCostDispatch = useCallback(
    (cost: number | null) => {
      dispatch(setShippingCost(cost))
    },
    [dispatch],
  )

  const calcShippingDate = useCallback(
    ({ product }: UseShippingsPropsType) => {
      const { isExpressShipping, shippingDate } = getShippingsData({
        currentCount: product?.currentCount,
        fastQty: product?.fastQty,
        totalQty: product?.totalQty,
        shippingShift: settings?.shippingShift,
        shippingFastTime: settings?.shippingFastTime,
        holidays: settings?.holidays,
        shippingMethods,
        // currentDate: new Date("2025-01-04T02:30:31.530Z"),
      })

      return { shippingDate, isExpressShipping }
    },
    [
      settings?.shippingShift,
      settings?.shippingFastTime,
      settings?.holidays,
      shippingMethods,
    ],
  )

  const { mutate: shippingCostMutate, isLoading: isFetchingShippingCost } =
    useMutation(fetchShippingCost, {
      onSuccess: (response) => {
        updateShippingCostDispatch(response?.shipping_cost || 0)
        dispatch(setPaymentMethods(response?.payment_methods || []))
      },
    })

  const refetchShippingCost = useCallback(
    (
      methodAlias: string,
      region: string,
      cartToken: string | null,
      shippingDate?: string,
    ) => {
      if (cartToken !== null && methodAlias.length > 0) {
        shippingCostMutate({
          shipping_method: methodAlias,
          region: region,
          cart: cartToken,
          shipping_date: shippingDate || undefined,
        })
      }
    },
    [shippingCostMutate],
  )

  const calculatedShippingData = useMemo(
    () =>
      product !== undefined
        ? calcShippingDate({
            product: {
              fastQty: product?.fastQty || 0,
              currentCount: product?.currentCount || 0,
              totalQty: product?.totalQty || 0,
            },
          })
        : {
            isExpressShipping: false,
            shippingDate: null,
          },
    [product, calcShippingDate],
  )

  return {
    ...calculatedShippingData,
    paymentMethods,
    isDateToOnlyCompany,
    recalcShippingDate: calcShippingDate,
    refetchShippingCost,
    isFetchingShippingCost,
    leftFreeShippingCourier,
    shippingCost,
    shippingType,
    updateShippingCost: updateShippingCostDispatch,
  }
}
