import Cookies from "universal-cookie";
import { parseCookies } from "universal-cookie/lib/utils";
import { IncomingMessage } from "http";
import { NextPageContext } from "next";
import { DaDataLocationDataType, fetchIpLocate } from "@/api/dadataAPI";
import { ProductPrintType } from "@/components/Cart/Print/types";
import { CheckoutFormFieldsType } from "@/components/Checkout/types";
import { ProductType, ProductWithChildType } from "@/components/Products/types";
import { getClientUserStorage, setUserStorage } from "@/hooks/auth";
import { setCartTokenStorage } from "@/hooks/cart/helpers";
import { getCatalogPath } from "@/hooks/filterCatalog/helpers";
import { DEFAULT_UNIT_MEASURE, removeZeroStart } from "@/hooks/product/helpers";
import { cssNoScroll } from "@/styles/utils/Utils";
import { BannerStringTypes, CatalogFetcherPropsType, ICategoryTreeItem, LocationExtendsType, LocationType, NavItemType, PropertiesType, SortByCatalogAlias, SortByType, SpecificationItemType, StoreBannersType } from "@/types";
import { IconNameType } from "@/ui/Icon";
import { getIsBot } from "@/utils/navigator";
import { ApiError, BannerApiType, ProductDetailListType, RequestBanner, SortTypeResponse } from "../../../contracts";
import { DATE_STORE, HOST_NAME_COOKIE_KEY, LOCATION_DEFAULT, LOCATION_NAME_COOKIE_KEY, MONTHS_DECLINATION, ROUTES, SITE_URL, VISIT_NAME_COOKIE_KEY } from "../constants";
export const SAMPLE_QUANTITY_LIMIT = 2;
export const cookies = new Cookies();
export const convertPennyToRub = (price?: number): number => {
  return +((price || 0) / 100).toFixed(2);
};
export const convertRubToPenny = (price?: number): number => {
  return +((price || 0) * 100).toFixed(2);
};
export const compareSortTypesWithIcon = (sortTypes: SortTypeResponse): SortByType => {
  const matchingIcons: Record<string | SortByCatalogAlias, IconNameType> = {
    [SortByCatalogAlias.PRICE_ASC]: "ArrowUp",
    [SortByCatalogAlias.PRICE_DESC]: "ArrowDown",
    [SortByCatalogAlias.POPULAR]: "Heart",
    [SortByCatalogAlias.BY_NEW]: "StarOutline"
  };
  const sorts: SortByType = {};
  for (const {
    name,
    alias
  } of sortTypes) {
    if (!alias || !name) {
      continue;
    }
    sorts[alias] = {
      value: alias,
      name: name,
      icon: matchingIcons[(alias as SortByCatalogAlias)]
    };
  }
  return Object.keys(sorts).length > 0 ? sorts : null;
};
export const timeToDate = (time: string): Date | null => {
  const parsedTime = time.split(":");
  if (parsedTime.length !== 2) {
    return null;
  }
  const dHour = +parsedTime[0];
  const dMinute = +parsedTime[1];
  const date = new Date();
  date.setHours(dHour);
  date.setMinutes(dMinute);
  return date;
};
type DateToStringType = (selectedDay: Date, withYear?: boolean) => string;
export const dateToString: DateToStringType = (selectedDay, withYear = true): string => {
  const normalizeDate = new Date(selectedDay);
  return `${normalizeDate.getDate()}\u00A0${MONTHS_DECLINATION["ru"][normalizeDate.getMonth()]} ${withYear ? normalizeDate.getFullYear() : ""}`;
};
export const shippingDateToString = (date: Date, messages?: {
  today?: string;
  tomorrow?: string;
  other?: (day: string) => string;
}): string => {
  let str: string;
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  switch (date.toISOString().split("T")[0]) {
    case new Date().toISOString().split("T")[0]:
      {
        str = messages?.today !== undefined ? messages.today : "Доставка сегодня";
        break;
      }
    case tomorrow.toISOString().split("T")[0]:
      {
        str = !!messages?.tomorrow ? messages.tomorrow : "Доставка завтра";
        break;
      }
    default:
      {
        str = messages?.other !== undefined ? messages.other(`${dateToString(date, false)}`) : `Доставка к\u00A0${dateToString(date, false)}`;
      }
  }
  return str;
};
export const onErrorFetcherCart = (error: ApiError): void => {
  if (error.status === 404) {
    const user = getClientUserStorage();
    if (user !== null) {
      setUserStorage({
        ...user,
        cart: null
      });
    }
    setCartTokenStorage(null);
    location.reload();
  }
};
export const compareSpecificationProducts = (specification: Record<string, SpecificationItemType> | null, datasource: Record<string, ProductWithChildType> | null): {
  products: Record<string, ProductWithChildType> | null;
  samples: Record<string, ProductWithChildType> | null;
} => {
  // полученные products которые есть в корзине
  // разделить по виду Товары или Образцы
  // пример: обрзец товара может быть в корзине - товара при этом может не быть

  const product: Record<string, ProductWithChildType> = {};
  const sample: Record<string, ProductWithChildType> = {};
  if (specification === null || datasource === null) {
    return {
      products: null,
      samples: null
    };
  }
  for (const [sKey, itemSpec] of Object.entries(specification)) {
    //если есть такой товар - сохранили
    if (!datasource[sKey]) {
      continue;
    }
    if (!!itemSpec?.quantity) {
      product[sKey] = {
        ...datasource[sKey]
      };
    }

    // если кол-во sample больше 0, т.е. ОБРАЗЕЦ в корзине
    if (!!itemSpec?.sample || !!itemSpec?.isSampleRemoved) {
      //если есть такой товар - сохранили
      sample[specification[sKey].uuid || ""] = datasource[sKey];
    }

    //если спецификация это комплект, сохраняем дочерние
    if (!!itemSpec.child) {
      for (const [sChildKey, specChild] of Object.entries(itemSpec.child)) {
        // если есть такой товар
        if (!datasource[sChildKey]) {
          continue;
        }
        if (!product[sKey]) {
          continue;
        }

        // сохранили как дочерний комплекта
        product[sKey].child = {
          ...product[sKey].child,
          ...{
            [sChildKey]: datasource[sChildKey]
          }
        };

        // если у дочернего есть образец - также сохранили
        // у составляющих комплекта тоже могут быть образцы
        // т.к. это реальные, обыкновенные товары
        if (!!specChild?.sample) {
          sample[sChildKey] = datasource[sChildKey];
        }
      }
    }
  }
  return {
    products: Object.keys(product).length > 0 ? product : null,
    samples: Object.keys(sample).length > 0 ? sample : null
  };
};

// 1 - слайдер главная
// * 2 - одиночный главная
// * 3 - одиночный в списке товаров
// * 4 - одиночный в каталоге под товарами
export const getBannerStringType = ({
  type
}: Pick<BannerApiType, "type">): BannerStringTypes | undefined => {
  switch (type) {
    case 1:
      {
        return "main_slider";
      }
    case 2:
      {
        return "main_single";
      }
    case 3:
      {
        return "catalog_products_single";
      }
    case 4:
      {
        return "catalog_under_products_single";
      }
    default:
      {
        return undefined;
      }
  }
};
export const scrollBodyDisable: (targetElement?: HTMLElement | Element) => void = () => {
  document.body.classList.add(cssNoScroll);
  // document.body.addEventListener("scroll", preventDefaultScroll)
};
export const scrollBodyEnable = (): void => {
  document.body.classList.remove(cssNoScroll);
  // document.body.removeEventListener("scroll", preventDefaultScroll)
};
export const setLocationCookie = (location: LocationType | null): void => {
  if (!!location) {
    cookies.set(LOCATION_NAME_COOKIE_KEY, JSON.stringify(location), {
      path: "/",
      expires: getExpireOneYear(),
      // domain: `.${SITE_DOMAIN}`,
      secure: true
    });
  } else {
    cookies.remove(LOCATION_NAME_COOKIE_KEY);
  }
};
export const getLocationCookie = (): LocationType | undefined => {
  const cookies = new Cookies();
  return cookies.get(LOCATION_NAME_COOKIE_KEY);
};
export const setVisitCookie = (visit: string | null): void => {
  if (!!visit) {
    cookies.set(VISIT_NAME_COOKIE_KEY, JSON.stringify(visit), {
      path: "/",
      expires: getExpireOneYear(),
      // domain: `.${SITE_DOMAIN}`,
      secure: true
    });
  } else {
    cookies.remove(VISIT_NAME_COOKIE_KEY);
  }
};
export const getLocationFormat = (location: LocationType | null): LocationExtendsType | null => {
  return location !== null ? {
    ...location,
    city_full: [location.city_type, location.city].filter(i => i !== null).join(" "),
    region_full: [location.region_type, location.region].filter(i => i !== null).join(" ")
  } : null;
};
export const getLocationRegion = (location: LocationExtendsType | null): string | null => {
  return location !== null ? [location?.city_full, location?.region_full].filter(item => !!item).join(",") : null;
};
export const getFormatResponseLocation = (payload: DaDataLocationDataType): LocationType => {
  return {
    region_type: payload?.region_type || null,
    region: payload?.region || null,
    city_type: payload?.city_type || null,
    city: payload?.city || null,
    region_kladr_id: payload?.region_kladr_id || null
  };
};
export const isCurrentYear = (date: Date | string): boolean => new Date(date).getFullYear() === new Date().getFullYear();
export const getTranslationProperties = (properties: PropertiesType): {
  name: string;
  value: string;
}[] => {
  const PROPERTIES_RU = {
    length: "длина",
    width: "ширина",
    height: "высота",
    size: "размер",
    weight: "вес"
  };
  return properties.filter(prop => !!prop.value && !!prop.name).map(p => {
    return {
      ...p,
      value: p.value || "",
      name: PROPERTIES_RU[p.name || ""] !== undefined ? PROPERTIES_RU[p.name || ""] : p.name || ""
    };
  });
};
export type LocalStorageCheckoutType = CheckoutFormFieldsType & {
  location: LocationType | null;
};
export const dateToISOString = (date: string | Date): string => {
  return new Date(date).toISOString().split("T")[0];
};
export const getDateToStore = (date: string | Date | null): typeof DATE_STORE => {
  if (date === null) {
    return {
      iso: null
    };
  }
  const d = new Date(date);
  return {
    iso: dateToISOString(d)
  };
};
export const declinationOfNum = (number: number, words: string[]): string => words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]];
export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};
export const getImagePath = (path?: string): string => {
  if (!path) {
    return "";
  }
  if (["http", "base64"].some(p => path.startsWith(p))) {
    return path;
  }
  if (["static"].some(p => path.startsWith(p))) {
    return `https://${path}`;
  }
  return `${SITE_URL}/${path}`.replaceAll("//", "/");
};
export const getSecondsHours = (countHours = 1) => 60 * 60 * countHours;
export const getSecondsDay = (countDays = 1) => getSecondsHours(24) * countDays;
export const getMilisecondsHours = (countHours = 1) => 1000 * getSecondsHours(countHours);
export const getMilisecondsDay = (countDays = 1) => getMilisecondsHours(24) * countDays;
export const getNumberOfDays = (start: string | Date, end: string | Date): number => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = getMilisecondsDay();

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  return Math.round(diffInTime / oneDay);
};
export const getExpiresByDay = (days: number) => new Date(new Date().getTime() + getMilisecondsDay(days));
export const getExpiresByHour = (hours: number) => new Date(new Date().getTime() + getMilisecondsHours(hours));
export const getExpireOneYear = (): Date => getExpiresByDay(365);
export const normalizeValueToSort = (str?: string): string | number => {
  const strInit = str || "";
  if (str === undefined) {
    return strInit;
  } else {
    const digitals = strInit.match(/\d+/g) || [];
    if (digitals.length === 0) {
      return strInit;
    } else {
      if (digitals.length >= 1) {
        // если число дробное, приходит через "," получаем такое число в формате number
        if (strInit.includes(",") && digitals.length >= 2) {
          return +`${digitals[0]}.${digitals[1]}`;
        } else {
          return +`${digitals[0]}`;
        }
      }
    }
  }
  return strInit.trim();
};
export const setLeadHitStockId = (id: string | null): void => {
  const KEY_NAME = "_lh_stock_id";
  if (id !== null) {
    localStorage.setItem(KEY_NAME, id);
  } else {
    localStorage.removeItem(KEY_NAME);
  }
};
export type RuleSortProduct = {
  uuid?: string;
  weight?: number;
};
export const filterProductsByCategory = ({
  products,
  category
}: {
  products: ProductDetailListType;
  category: string | null;
}): ProductDetailListType => {
  if (category === null) {
    return products;
  }
  return products.filter(p => (p.categories || []).includes(category));
};
export const sortProductsWeightRule = ({
  products,
  rules
}: {
  products: ProductDetailListType;
  rules?: RuleSortProduct[];
}): ProductDetailListType => {
  const weightRule = rules || [];
  const _getCurrRule = (uuid?: string): RuleSortProduct | undefined => weightRule.find(w => w.uuid === uuid);
  return [...products].sort((a, b) => {
    const weightA = _getCurrRule(a.uuid)?.weight;
    const weightB = _getCurrRule(b.uuid)?.weight;
    if (weightA !== undefined && weightB !== undefined) {
      return weightA > weightB ? 1 : weightA < weightB ? -1 : 0;
    } else {
      return 0;
    }
  }).sort((a, b) => {
    return !!a.total_qty === !!b.total_qty ? 0 : !!a.total_qty ? -1 : 1;
  });
};
export const getIp = (req: IncomingMessage | undefined, mock = ""): string | undefined => {
  if (!!mock) {
    return mock;
  }
  const forwarded = req?.headers["x-forwarded-for"];
  let ip = typeof forwarded === "string" ? forwarded.split(/, /)[0] : (req?.headers["x-real-ip"] as string | undefined);
  if (!ip) {
    ip = req?.socket?.remoteAddress;
  }
  return ip === "::1" ? undefined : ip;
};
export const toSpecialChars = (string: string): string => {
  return string.replaceAll(/&/g, "&amp;").replaceAll(/>/g, "&gt;").replaceAll(/</g, "&lt;").replaceAll(/"/g, "&quot;").trim();
};
export const getSearchParamsCatalogByRequest = ({
  data,
  options: {
    isSearch = false,
    fields = []
  } = {}
}: CatalogFetcherPropsType) => {
  const searchParams = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (!!value) {
      searchParams.set(key, value);
    }
  });
  if (isSearch) {
    searchParams.set("is_search", String(isSearch));
  }
  if (fields.length > 0) {
    searchParams.set("fields", String(fields));
  }
  return searchParams;
};
export const makeBanners = (payload: RequestBanner[]) => {
  const banners = ({} as StoreBannersType);
  for (const item of payload) {
    const typeString = getBannerStringType({
      type: item.type
    });
    if (!!typeString) {
      if (banners[typeString] === undefined) {
        banners[typeString] = [];
      }
      banners[typeString].push(item);
    }
  }
  return Object.keys(banners).length > 0 ? banners : null;
};
export const getCategoryUuidsChild = (category: ICategoryTreeItem): string[] => {
  const uuids: string[] = [];
  const _process = (category: ICategoryTreeItem) => {
    if (Object.keys(category.children || {}).length <= 0) {
      return;
    }
    for (const key of Object.keys(category.children)) {
      const id = category.children[key]?.uuid;
      if (id !== undefined) {
        uuids.push(id);
        _process(category.children[key]);
      }
    }
  };
  _process(category);
  return uuids;
};
export const getSubItemsBreadcrumbs = (treeSlice: ICategoryTreeItem | null): NavItemType[] => treeSlice !== null ? Object.entries(treeSlice.children || {}).reduce((acc: NavItemType[], [, {
  uuid = "",
  alias = "",
  name = "",
  image,
  product_qty = 0,
  name_breadcrumbs = ""
}]) => {
  return product_qty <= 0 ? [...acc] : [...acc, {
    id: uuid,
    path: getCatalogPath(alias),
    title: name_breadcrumbs || name || "",
    image,
    qty: product_qty
  }];
}, []) : [];
export const getOrderSortAsc = (a: number | string, b: number | string) => a > b ? 1 : a < b ? -1 : 0;
export const getOrderSortDesc = (a: number | string, b: number | string) => a > b ? -1 : a < b ? 1 : 0;
export const numberWithThousands = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
type GetPricePDFFnType = (payload: {
  price: number;
  withCurrency?: boolean;
}) => string;
export const getPricePDF: GetPricePDFFnType = ({
  price,
  withCurrency = true
}) => {
  const currency = withCurrency ? "руб" : "";
  return `${numberWithThousands(convertPennyToRub(price))}${currency ? ` ${currency}` : ""}`;
};
export const configPrintProducts = ({
  products,
  specification
}: {
  specification: Record<string, SpecificationItemType> | null;
  products: Record<string, ProductWithChildType> | null;
}): ProductPrintType[] => {
  const p: ProductPrintType[] = [];
  if (!specification || !products) {
    return [];
  }
  for (const [uuid, {
    images = [],
    name = "",
    code = "",
    alias = "",
    price = 0,
    unit = DEFAULT_UNIT_MEASURE
  }] of Object.entries(products)) {
    const currSpec = specification[uuid];
    if (!currSpec) {
      continue;
    }
    const {
      quantity = 0,
      isRemoved = false
    } = currSpec;
    if (isRemoved) {
      continue;
    }
    p.push({
      uuid: uuid,
      code: removeZeroStart(code),
      price: price,
      cost: price * quantity,
      quantity: quantity,
      name: name,
      image: images[0] || "",
      alias: alias,
      unit: unit,
      quantityFormatted: `${quantity} ${unit}`
    });
  }
  return p;
};
export const configPrintSamples = ({
  samples,
  specification
}: {
  specification: Record<string, SpecificationItemType> | null;
  samples: Record<string, ProductWithChildType> | null;
}): ProductPrintType[] => {
  const s: ProductPrintType[] = [];
  if (!specification || !samples) {
    return [];
  }
  for (const [uuid, {
    images = [],
    name = "",
    code = "",
    alias = "",
    price = 0,
    unit = DEFAULT_UNIT_MEASURE
  }] of Object.entries(samples)) {
    const currSpec = specification[uuid];
    if (!currSpec) {
      continue;
    }
    const {
      sample = 0,
      isSampleRemoved = false
    } = currSpec;
    if (isSampleRemoved) {
      continue;
    }
    s.push({
      uuid: uuid,
      code: removeZeroStart(code),
      price: price,
      cost: price * sample,
      quantity: sample,
      name: name,
      image: images[0] || "",
      alias: alias,
      unit: unit,
      quantityFormatted: `${sample} ${unit}`
    });
  }
  return s;
};
type MathKeysFnType = (payload: {
  stateKeys: null | string[];
  newKeys: string[];
  limit?: number;
}) => string[];
export const matchKeys: MathKeysFnType = ({
  stateKeys,
  newKeys,
  limit
}) => {
  if (stateKeys === null) {
    return newKeys;
  }

  // храним сразу обратный порядок
  // последний просмотренный - первый в массиве
  let keysMatching = getUnique([...newKeys, ...stateKeys]);
  if (limit !== undefined) {
    keysMatching = keysMatching.slice(0, limit);
  }
  return keysMatching;
};
export const sortProductsFromApi = (products: ProductType[], uuidsSorting: string[] | null) => {
  if (!uuidsSorting) {
    return products;
  }
  return (uuidsSorting.map(uuidSorted => products.find(({
    uuid = ""
  }) => uuid === uuidSorted)).filter(p => !!p) as ProductType[]);
};
export const addZeroBeforeNumber = (num: number) => String(num).padStart(2, "0");
export const treeFlattenWithLevels = <T,>(roots: T[], getChildren: (node: T) => T[] | undefined, level = 0): {
  level: number;
  node: T;
}[] => roots.map(node => {
  const children = getChildren(node);
  if (!!children) {
    return [{
      node,
      level
    }, ...treeFlattenWithLevels(children, getChildren, level + 1)];
  } else {
    return [{
      node,
      level
    }];
  }
}).flat();
export const readBooleanFromStorage = (storage: Storage, key: string) => {
  const rawItem = storage.getItem(key);
  return !!rawItem ? (JSON.parse(rawItem) as boolean) : false;
};
export const getRandomValue = (max: number, min: number, round = 1) => {
  return +(Math.random() * (max - min) + min).toFixed(round);
};
export const gtagSend = ({
  event
}: {
  event: string;
}) => {
  if (!window["dataLayer"]) {
    return;
  }
  window["dataLayer"].push({
    event: event
  });
};
export const groupBy = <I, V extends string | number | undefined>(items: I[], getProperty: (it: I) => V) => {
  const records: Record<string, I[]> = {};
  for (const item of items) {
    const prop = `${getProperty(item)}`;
    if (Array.isArray(records[prop])) {
      records[prop].push(item);
    } else {
      records[prop] = [item];
    }
  }
  return records;
};
export const mapValues = <I, R, O extends Record<string, I>, K extends string | symbol>(obj: O, mapFn: (key: K, item: I, obj: O) => R) => {
  const result: Record<string, R> = {};
  for (const [key, value] of Object.entries(obj)) {
    result[key] = mapFn((key as K), value, obj);
  }
  return result;
};
export const getUnique = <T,>(arr: T[]): T[] => [...new Set<T>(arr)];
export const getUniqueByKeys = <T,>(arr: T[], keys: (keyof T)[]): T[] => {
  const seen = new Set();
  const result: T[] = [];
  arr.forEach(item => {
    const key = keys.map(field => item[field]).join("|");
    if (!seen.has(key)) {
      seen.add(key);
      result.push(item);
    }
  });
  return result;
};
export const getHighPriceProducts = (products: ProductType[]) => products.reduce((price, {
  price: currPrice = 0
}, index) => index === 0 ? currPrice : price > currPrice ? price : currPrice, 0);
export const getLowPriceProducts = (products: ProductType[]) => products.reduce((price, {
  price: currPrice = 0
}, index) => index === 0 ? currPrice : price < currPrice ? price : currPrice, 0);
export const groupByPart = <T,>(arr: T[], parts: number) => {
  const copiedArr = [...arr];
  const restItems = copiedArr.splice(copiedArr.length - copiedArr.length % parts, copiedArr.length);
  let out: T[][] = [];
  let i = 0;
  while (i < copiedArr.length) {
    out = [...out, copiedArr.slice(i, i + parts)];
    i += parts;
  }
  if (restItems.length > 0) {
    out.push(restItems);
  }
  return out;
};
export const createFullPath = (basePath?: string | null) => {
  if (!basePath) {
    return null;
  }
  const cHost = cookies.get(HOST_NAME_COOKIE_KEY);
  if (!cHost) {
    return null;
  }
  return `https://${cHost}${basePath}`;
};
export const isValidPathname = (pathname = "") => {
  // существуют страницы, создающие фид или отображающие фид по урлу вместо файла
  // эти страницы могут парсить роботы и множество раз
  // нам не нужно чтобы dadata тратила на это запросы
  return pathname === ROUTES.home || Object.values(ROUTES).filter(v => v !== ROUTES.home).some(path => pathname.startsWith(path));
};
export const findIndexBy = <T,>(arr: T[], match: (v: T) => boolean) => {
  for (let i = 0; i < arr.length; i++) {
    const it = arr[i];
    const matched = match(it);
    if (matched) {
      return i;
    }
  }
  return -1;
};
export const getEntityBasePath = (path: string, isAbsolute = false): string => `${isAbsolute ? SITE_URL : ""}${path}`;
export const getTrimmingValue = (value?: string) => {
  if (!value) {
    return "";
  }

  // В этой строке удаляются все пробелы в начале (^) и в конце ($).
  // g в конце регулярного выражения означает: глобальный,
  // т. е. сопоставлять и заменять все вхождения.
  return value.trim().replace(/\s+/g, " ");
};
export const getTransliteration = (word = "") => {
  const converter = {
    ["а"]: "a",
    ["б"]: "b",
    ["в"]: "v",
    ["г"]: "g",
    ["д"]: "d",
    ["е"]: "e",
    ["ё"]: "yo",
    ["ж"]: "zh",
    ["з"]: "z",
    ["и"]: "i",
    ["й"]: "y",
    ["к"]: "k",
    ["л"]: "l",
    ["м"]: "m",
    ["н"]: "n",
    ["о"]: "o",
    ["п"]: "p",
    ["р"]: "r",
    ["с"]: "s",
    ["т"]: "t",
    ["у"]: "u",
    ["ф"]: "f",
    ["х"]: "kh",
    ["ц"]: "ts",
    ["ч"]: "ch",
    ["ш"]: "sh",
    ["щ"]: "shch",
    ["ы"]: "y",
    ["э"]: "e",
    ["ю"]: "yu",
    ["я"]: "ya",
    [" "]: "-",
    ["ь"]: "",
    ["ъ"]: ""
  };
  return word.toLowerCase().split("").map(char => converter[char] ?? char).join("");
};

// метод возвращает
// либо найденную
// либо не точную, которую нужно будет подтвердить
// либо null если бот или страница техническая
export const makeLocationSSR = async ({
  req,
  asPath: pathname = ""
}: Pick<NextPageContext, "req" | "asPath">): Promise<{
  location: LocationType;
  cookieLocation: string;
} | null> => {
  // существуют страницы, создающие фид или отображающие фид по урлу вместо файла
  // эти страницы могут парсить роботы и множество раз
  // нам не нужно чтобы dadata тратила на это запросы
  if (pathname !== ROUTES.home && !Object.values(ROUTES).filter(v => v !== ROUTES.home).some(path => pathname.indexOf(path) > -1)) {
    return null;
  }
  const userAgent = req?.headers["user-agent"] || "";
  const youIsBot = getIsBot(userAgent);
  if (youIsBot) {
    return null;
  }
  let cookieLocation = "";
  const parsedCookie = parseCookies(req?.headers.cookie);
  let location: LocationType;
  const locationCookie = !!parsedCookie ? (parsedCookie[LOCATION_NAME_COOKIE_KEY] as string | undefined) || "" : "";
  if (!locationCookie) {
    const ip = getIp(req);
    if (!!ip) {
      const ipLocateResult = await fetchIpLocate({
        ip
      });
      if (!!ipLocateResult && !!ipLocateResult.location) {
        location = {
          ...getFormatResponseLocation(ipLocateResult.location.data),
          is_guessed: false
        };
      } else {
        location = {
          ...LOCATION_DEFAULT,
          is_guessed: true
        };
      }
    } else {
      location = {
        ...LOCATION_DEFAULT,
        is_guessed: true
      };
    }
    cookieLocation = `${LOCATION_NAME_COOKIE_KEY}=${encodeURIComponent(JSON.stringify(location))}; Path=/; Expires=${getExpireOneYear().toUTCString()}`;
  } else {
    location = JSON.parse(locationCookie);
  }
  return {
    location,
    cookieLocation
  };
};
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));