import { useMutation, useQueries, useQuery } from "react-query"
import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useRouter } from "next/router"
import {
  fetchBindUserCart,
  fetchCart,
  fetchChangeQtyProduct,
  fetchChangeQtySample,
  fetchClearCart,
  fetchMergeUserCart,
  fetchUpdateStatusCart,
} from "@/api/cartAPI"
import { fetchProductsList } from "@/api/productsAPI"
import { ProductType } from "@/components/Products"
import { useAuth } from "@/hooks/auth"
import {
  CART_STATUS,
  ROUTES_ENABLE_STATUS_ORDERING,
} from "@/hooks/cart/constants"
import {
  chunkArray,
  formatProductsToSpecification,
  getCartTokenStorage,
  getEntityCartIds,
  getProductQty,
  getSampleQty,
  setCartTokenStorage,
} from "@/hooks/cart/helpers"
import { CartContextType } from "@/hooks/cart/types"
import { toCodeFormat } from "@/hooks/feedback"
import { usePromocode } from "@/hooks/promocode"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import { useShippings } from "@/hooks/shippings/shippings"
import { useDevLog } from "@/hooks/useDevLog"
import {
  addProductsFetching,
  appendDataSource,
  clearCart,
  removeProductsFetching,
  removeProductSpecification,
  setIsFetchingProductsMerge,
  setIsInit,
  setIsShowMerge,
  setMergeProducts,
  setMergeSpecification,
  setMinShippingDate,
  setMultiAddedToCartFetching,
  setMultiAddedToCartFinish,
  setMultiAddedToCartSuccess,
  setNextShippingDate,
  setPickupDate,
  setProductCost,
  setSpecification,
  setStatus,
  setToken,
  setTotalCost,
  updateProductPriceUnit,
  updateSpecification,
} from "@/store/reducers/cartSlice"
import { ChangeQtyResponseClient, SpecificationItemType } from "@/types"
import {
  compareSpecificationProducts,
  createFullPath,
  dateToISOString,
  getUnique,
  onErrorFetcherCart,
} from "@/utils/common/helpers"
import { ROUTES, TIMEOUT_SUCCESS } from "@/utils/constants"

import {
  ApiError,
  ChangeQtyRequest,
  ProductSpecificationType,
} from "../../../contracts"

export const CartContext = createContext<CartContextType | null>(null)
export const Provider: FC = ({ children }) => {
  const { sendLogMessage } = useDevLog()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {
    specification,
    datasource,
    totalCost,
    productCost,
    token,
    order,
    productsFetching,
    merge: {
      products: productsMerge,
      specification: specificationMerge,
      isShow: isShowMerge,
      isFetchingProducts: isFetchingProductsMerge,
    },
    isInit,
    pickup,
    shipping,
    multiAddedToCart,
    status,
  } = useAppSelector((state) => state.cart)
  const {
    miniCartHeader: { isShow: isShowMiniCart },
  } = useAppSelector(({ common }) => common.modals)
  const [isShowToLogin, setIsShowToLogin] = useState(false)

  const { isInit: isInitAuth, isAuth, user, updateUser } = useAuth()

  const { updatePromocode, updateDiscount } = usePromocode({
    cart: token,
  })

  const { recalcShippingDate, shippingCost } = useShippings()

  const router = useRouter()

  const [errorLoadingProducts, setErrorLoadingProducts] = useState<
    string | undefined
  >()
  const withOutDataSourceIds = useMemo(() => {
    const specificationIds = getEntityCartIds(specification)
    const datasourceIds = getEntityCartIds(datasource)
    const result = specificationIds.filter(
      (sId) => !datasourceIds.includes(sId),
    )

    if (user?.number === "67593240") {
      sendLogMessage({
        hash: "CREATE_KEYS_DATASOURCE",
        topic: `Ключи для данных корзины`,
        payload: [
          {
            label: "specificationIds",
            value: specificationIds.join(", "),
          },
          {
            label: "datasourceIds",
            value: datasourceIds.join(", "),
          },
          {
            label: "result",
            value: result.join(", "),
          },
        ],
      })
    }

    return result
  }, [datasource, sendLogMessage, specification, user?.number])

  const [isShowShare, setIsShowShare] = useState<boolean>(false)
  const [sharePath, setSharePath] = useState<string | null>(null)

  const cartCount = useMemo(() => {
    let count = 0

    if (specification === null) {
      return count
    }
    for (const [, itemSpec] of Object.entries(specification)) {
      count += getProductQty(itemSpec) > 0 ? 1 : 0
      count += getSampleQty(itemSpec) > 0 ? 1 : 0
    }
    return count
  }, [specification])

  const shippingDatesOrdered = useMemo(() => {
    const dates: string[] = []
    if (specification === null || datasource === null) {
      return []
    }

    for (const [key, itemSpec] of Object.entries(specification)) {
      const _productQty = getProductQty(itemSpec)
      const _sampleQty = getSampleQty(itemSpec)
      if (_sampleQty === 0 && _productQty === 0) {
        continue
      }
      const entity: ProductType = (datasource || {})[key] || null
      if (!entity) {
        continue
      }
      const { shippingDate: date } = recalcShippingDate({
        product: {
          fastQty: entity?.fast_qty || 0,
          currentCount: _productQty + _sampleQty,
          totalQty: entity?.total_qty || 0,
        },
      })
      if (date !== null) {
        dates.push(dateToISOString(date))
      }
    }

    return getUnique(dates).sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    )
  }, [datasource, recalcShippingDate, specification])

  const { products, samples } = useMemo(
    () => compareSpecificationProducts(specification, datasource),
    [specification, datasource],
  )

  const computedTotalCost = totalCost + (shippingCost || 0)

  const {
    refetch: refetchCart,
    isFetching: isFetchingCart,
    isLoading,
  } = useQuery(
    ["cart", token, isInit, isShowMerge],
    () => (!!token ? fetchCart(token || "") : null),
    {
      enabled: (!!token || isInit) && !isShowMerge,
      refetchOnWindowFocus: true,
      onSuccess: (response) => {
        if (isAuth) {
          if (user?.number === "67593240") {
            sendLogMessage({
              hash: "SUCCESS_GET_CART",
              topic: "Данные корзины - успешно",
              payload: [
                {
                  label: "Корзина",
                  value: `${token || ""}`,
                },
                {
                  label: "Данные корзины",
                  value: JSON.stringify(response),
                },
              ],
            })
          }
        }

        if (!!response) {
          const {
            state = null,
            products = [],
            total_cost = 0,
            discount = 0,
            product_cost = 0,
            promocode = null,
          } = response
          dispatch(setStatus(state as CART_STATUS))
          dispatch(setSpecification(products))
          updateTotalCostDispatch(total_cost)
          updateProductCostDispatch(product_cost)
          updateDiscount(discount)
          updatePromocode(promocode)
        } else {
          dispatch(setStatus(null))
          dispatch(setSpecification([]))
          updateTotalCostDispatch(0)
          updateProductCostDispatch(0)
          updateDiscount(0)
          updatePromocode(null)
        }
      },
      onError: (error: ApiError) => {
        if (isAuth) {
          sendLogMessage({
            hash: "ERROR_GET_CART",
            topic: "Данные корзины - ошибка",
            payload: [
              {
                label: "Корзина",
                value: `${token || ""}`,
              },
              {
                label: "Данные корзины",
                value: JSON.stringify(error),
              },
            ],
          })
        }
        onErrorFetcherCart(error)
      },
    },
  )

  const isEmpty = !isLoading
    ? !!specification &&
      !Object.keys(specification).length &&
      !Object.keys(datasource || {}).length
    : false

  const {
    mutate: mergeCartUserMutate,
    isLoading: isFetchingMerge,
    isSuccess: isSuccessMerge,
  } = useMutation(fetchMergeUserCart, {
    onSuccess: (response) => {
      if (user !== null) {
        updateUser({ ...user, cart: response.cart })
      }

      updateToken(response.cart)

      dispatch(setIsShowMerge(false))
      setTimeout(() => {
        dispatch(setMergeProducts(null))
        dispatch(setMergeSpecification(null))
      }, 300)
    },
    onError: (error: ApiError, request) => {
      sendLogMessage({
        hash: "ERROR_MERGE_CART_MERGE",
        topic: `Слияние корзин - ошибка`,
        payload: [
          {
            label: "request cart",
            value: toCodeFormat(`${request.cart}`),
          },
          {
            label: "error",
            value: JSON.stringify(error),
          },
        ],
      })
    },
  })

  const { mutate: bindUserCartMutate } = useMutation(fetchBindUserCart, {
    onSuccess(_, request) {
      updateToken(request.cart)
    },
    onError: (error, request) => {
      sendLogMessage({
        hash: "ERROR_BIND_CART",
        topic: "Привязка корзины",
        payload: [
          {
            label: "token",
            value: toCodeFormat(`${request.cart}`),
          },
          {
            label: "error",
            value: JSON.stringify(error),
          },
        ],
      })
    },
  })

  // разделяем идентификаторы товаров на равные порции
  // чтобы ГЕТ запрос не упал в ошибке
  const chunkedWithOutDataSourceIds = useMemo(
    () =>
      withOutDataSourceIds.length > 0
        ? chunkArray(withOutDataSourceIds, 50)
        : [],
    [withOutDataSourceIds],
  )

  // на основе сохраненных ids товаров
  // получаем данные этих товаров для вывода
  const queriesDataSource = useQueries(
    chunkedWithOutDataSourceIds.map((chunk, index) => {
      return {
        queryKey: [
          "datasource",
          String(index),
          router.pathname,
          isShowMiniCart,
        ],
        queryFn: () =>
          fetchProductsList({
            uuids: chunk.join(",") || "",
          }),
        refetchOnWindowFocus: false,
        enabled:
          chunk.length > 0 &&
          (isShowMiniCart ||
            [ROUTES.cart, ROUTES.checkout].some((route) =>
              router.pathname.includes(route),
            )),
      }
    }),
  )

  // дожидаемся загрузки всех товаров
  const isFetchingDataSource = queriesDataSource.some(
    (query) => query.isFetching,
  )

  const fillDataMergeCart = useCallback(
    async (tokenCart: string) => {
      const responseCart = await fetchCart(tokenCart).catch(
        (error: ApiError) => {
          console.log(
            "ERROR_MERGE_CART_FETCHED_CART - ошибка получения корзины: ",
            error,
          )
          sendLogMessage({
            hash: "ERROR_MERGE_CART_FETCHED_CART",
            topic: "Слияние корзин - ошибка получения корзины",
            payload: [
              {
                label: "token",
                value: toCodeFormat(`${tokenCart}`),
              },
              {
                label: "error",
                value: JSON.stringify(error),
              },
            ],
          })

          onErrorFetcherCart(error)
        },
      )

      if (!responseCart) {
        return Promise.reject(
          new Error("Merge:FetchCartError: ошибка получения корзины"),
        )
      }

      const { products = [] } = responseCart

      if ((products || []).length <= 0) {
        sendLogMessage({
          hash: "ERROR_MERGE_CART_PRODUCTS",
          topic: "Слияние корзин - Нет товаров в корзине",
          payload: [
            {
              label: "token",
              value: toCodeFormat(`${tokenCart}`),
            },
            {
              label: "error",
              value: `Нет товаров в корзине`,
            },
          ],
        })

        return Promise.reject(
          new Error("ERROR_MERGE_CART_PRODUCTS: Нет товаров в корзине"),
        )
      }

      dispatch(
        setMergeSpecification(
          formatProductsToSpecification({
            products: products,
            specification,
          }),
        ),
      )
      dispatch(setIsShowMerge(true))
      dispatch(setIsFetchingProductsMerge(true))

      const productsData = await fetchProductsList({
        uuids: products.map((p) => p.uuid).join(","),
      })
        .catch((error: ApiError) => {
          console.log(
            "ERROR_MERGE_CART_FETCHED_PRODUCTS: ошибка получения товаров корзины",
          )
          sendLogMessage({
            hash: "ERROR_MERGE_CART_FETCHED_PRODUCTS",
            topic: "Слияние корзин - ошибка получения товаров корзины",
            payload: [
              {
                label: "token",
                value: toCodeFormat(`${tokenCart}`),
              },
              {
                label: "error",
                value: JSON.stringify(error),
              },
            ],
          })
          dispatch(setMergeProducts([]))
        })
        .finally(() => {
          dispatch(setIsFetchingProductsMerge(false))
        })
      dispatch(setMergeProducts(productsData || []))
    },
    [dispatch, sendLogMessage, specification],
  )

  const getUrl = useCallback((): string | null => {
    if (specification === null) {
      return null
    }

    const pathname = `${ROUTES.cart}${ROUTES.shared}/${Object.keys(
      specification,
    )
      .filter(
        (key) => !!specification[key].quantity || !!specification[key].sample,
      )
      .map((key) => {
        const {
          uuid = "",
          isRemoved,
          quantity = 0,
          sample = 0,
        } = specification[key]
        return `${uuid}:${!!isRemoved ? 0 : quantity ?? 0}:${sample ?? 0}`
      })
      .join(",")}`

    return createFullPath(pathname)
  }, [specification])

  const { mutateAsync: changeQtyProductMutateAsync } = useMutation(
    fetchChangeQtyProduct,
    {
      onSuccess: (
        response: ChangeQtyResponseClient,
        variables: ChangeQtyRequest,
      ) => {
        if (token === null) {
          updateToken(response.cart || null)
        }
        updateTotalCostDispatch(response.total_cost)
        dispatch(removeProductsFetching(variables.product))
      },
      onError: (error: ApiError, variables: ChangeQtyRequest) => {
        dispatch(removeProductsFetching(variables.product))
      },
      onMutate: (variables: ChangeQtyRequest) => {
        dispatch(addProductsFetching(variables.product))
      },
    },
  )

  const { mutateAsync: changeQtySampleMutateAsync } = useMutation(
    fetchChangeQtySample,
    {
      onSuccess: (
        response: ChangeQtyResponseClient,
        variables: ChangeQtyRequest,
      ) => {
        if (token === null) {
          updateToken(response.cart || null)
        }
        updateTotalCostDispatch(response.total_cost || 0)
        dispatch(removeProductsFetching(variables.product))
      },
      onError: (error: ApiError, variables: ChangeQtyRequest) => {
        dispatch(removeProductsFetching(variables.product))
      },
      onMutate: (variables: ChangeQtyRequest) => {
        dispatch(addProductsFetching(variables.product))
      },
    },
  )

  const share = useCallback(() => {
    setSharePath(getUrl())
    setIsShowShare(true)
  }, [getUrl])

  const multiAddToCart = useCallback(
    async (
      restProducts: Record<string, ProductSpecificationType>,
      tokenCart: string | null,
      afterToCart?: boolean,
      onSuccess?: () => void,
    ) => {
      dispatch(setMultiAddedToCartFetching(true))
      dispatch(setMultiAddedToCartSuccess(false))

      const products = { ...restProducts }
      const promises: Promise<ChangeQtyResponseClient>[] = []

      const _run = async (
        products: Record<string, ProductSpecificationType>,
        tokenCart: string | null,
      ) => {
        const token = tokenCart
        const productsKeys = Object.keys(products)
        if (productsKeys.length === 0) {
          return
        }

        if (token === null) {
          const firstKey: string = productsKeys[0]
          let newToken: string | null = null

          if ((products[firstKey].quantity || 0) > 0) {
            const resAdded = await changeQtyProductMutateAsync({
              product: products[firstKey].uuid || "",
              quantity: products[firstKey].quantity || 0,
              cart: undefined,
            })
            if (!!resAdded) {
              newToken = resAdded.cart || null
            }
          } else {
            const resAdded = await changeQtySampleMutateAsync({
              product: products[firstKey].uuid || "",
              quantity: products[firstKey].sample || 0,
              cart: undefined,
            })
            if (!!resAdded) {
              newToken = resAdded.cart || null
            }
          }
          if (newToken !== null) {
            if (products[firstKey]) {
              delete products[firstKey]
            }
          }
          await _run(products, newToken)
        } else {
          productsKeys.map((p) => {
            const currentSpeicification =
              specification !== null ? specification[p] || null : null
            if ((products[p].quantity || 0) > 0) {
              promises.push(
                (async () => {
                  return await changeQtyProductMutateAsync({
                    product: products[p].uuid || "",
                    quantity: products[p].quantity || 0, // здесь уже объединенное кол-во должно быть
                    cart: token,
                  })
                })(),
              )
            }
            if ((products[p].sample || 0) > 0) {
              promises.push(
                changeQtySampleMutateAsync({
                  product: products[p].uuid || "",
                  quantity:
                    (products[p].sample || 0) +
                    (currentSpeicification?.sample || 0),
                  cart: token,
                }),
              )
            }
          })
        }
      }

      await _run(products, tokenCart)

      if (promises.length > 0) {
        await Promise.all(promises)
          .finally(() => {
            dispatch(setMultiAddedToCartFetching(false))
            dispatch(setMultiAddedToCartSuccess(true))
            dispatch(setMultiAddedToCartFinish(true))
            setTimeout(() => {
              dispatch(setMultiAddedToCartFinish(false))
            }, TIMEOUT_SUCCESS)
            if (!!onSuccess) {
              onSuccess()
            }

            if (afterToCart) {
              void router.replace(ROUTES.cart)
            } else {
              void refetchCart()
            }
          })
          .catch((error) => console.log("error multi added: ", error))
      }
    },
    [
      dispatch,
      changeQtyProductMutateAsync,
      changeQtySampleMutateAsync,
      specification,
      refetchCart,
    ],
  )

  const { mutate: clearCartMutate } = useMutation(fetchClearCart, {
    onSuccess: () => {
      dispatch(clearCart())
      setIsFetching(false)
    },
    onError: (error: ApiError) => {
      if (error.status === 418) {
        setCartTokenStorage(null)
      }
      setIsFetching(false)
    },
    onMutate: () => {
      setIsFetching(true)
    },
  })

  const { mutate: updateStatusCartMutate } = useMutation(
    fetchUpdateStatusCart,
    {
      onSuccess: (response) => {
        dispatch(setStatus(response?.state || null))
      },
    },
  )

  const showToLogin = useCallback(() => {
    setIsShowToLogin(true)
  }, [setIsShowToLogin])

  const hideToLogin = useCallback(() => {
    setIsShowToLogin(false)
  }, [setIsShowToLogin])

  const updateToken = useCallback(
    (token: string | null) => {
      setCartTokenStorage(token)
      dispatch(setToken(token))

      if (isInitAuth && isAuth) {
        if (user !== null) {
          updateUser({ ...user, cart: token })
        }
      }
    },
    [dispatch, isAuth, isInitAuth, updateUser, user],
  )

  // обертка диспатчер
  const updateSpecificationDispatch = useCallback(
    (specification: SpecificationItemType) => {
      dispatch(updateSpecification(specification))
    },
    [dispatch],
  )

  // обертка диспатчер
  const updateTotalCostDispatch = useCallback(
    (cost?: number) => {
      dispatch(setTotalCost(cost || 0))
    },
    [dispatch],
  )

  // обертка диспатчер
  const updateProductCostDispatch = useCallback(
    (cost?: number) => {
      dispatch(setProductCost(cost || 0))
    },
    [dispatch],
  )

  const clearCartHandler = useCallback(() => {
    console.log("clear token ", token)
    if (!!token) {
      clearCartMutate({
        uid: token,
      })
    }
  }, [clearCartMutate, token])

  const updateProductPriceUnitHandle = useCallback(
    ({ uuid, priceUnit }: { uuid: string; priceUnit: number }) => {
      dispatch(updateProductPriceUnit({ uuid, priceUnit }))
    },
    [dispatch],
  )

  const onAuthHandle = useCallback(async () => {
    const userCart = user?.cart

    // при входе в аккаунт, создаем правильные данные

    // у пользователя есть корзина
    // и не было корзины до авторизации
    // просто обновляем токен
    if (!!userCart && !token) {
      updateToken(userCart)
    }

    // у пользователя нет корзины (новый пользователь или прошлая корзина была закрыта)
    // и есть корзина до авторизации
    // привязываем текущий токен к пользователю
    if (!userCart && !!token) {
      bindUserCartMutate({
        cart: token,
      })
    }

    // у пользователя есть корзина
    // и есть корзина до авторизации
    // должны предложить объединить корзины
    if (!!userCart && !!token && userCart !== token) {
      await fillDataMergeCart(userCart)
    }
  }, [user?.cart, token, updateToken, bindUserCartMutate, fillDataMergeCart])

  const onNotAuthHandle = useCallback(() => {
    const tokenStorage = getCartTokenStorage()
    if (tokenStorage !== null) {
      updateToken(tokenStorage)
    }
  }, [updateToken])

  const removeSpecificationHandle = useCallback(
    (uuid: string) => {
      dispatch(removeProductSpecification({ uuid: uuid }))
    },
    [dispatch],
  )

  const acceptMerge = useCallback(() => {
    if (!isShowMerge) {
      return
    }
    const notAuthCart = getCartTokenStorage()

    if (!notAuthCart) {
      return
    }
    dispatch(setMultiAddedToCartFetching(true))
    mergeCartUserMutate({ cart: notAuthCart })
  }, [mergeCartUserMutate, dispatch, isShowMerge])

  const cancelMerge = useCallback(() => {
    // cancel вызывается при закрытии модального окна слияния
    // окно может быть закрыто
    // по ошибке, по отказу от слияния и после успешного слияния
    // после успеха - биндить корзину не нужно

    if (!isShowMerge) {
      return
    }

    // если отменили слияние корзин
    // тогда привязываем к пользователю только ту корзину,
    // которая была в сценарии нелогина
    const notAuthCart = getCartTokenStorage()
    console.log("cancelMerge notAuthCart ", notAuthCart)

    if (!!notAuthCart) {
      bindUserCartMutate({
        cart: notAuthCart,
      })
    }

    // и очищаем все остальное
    dispatch(setIsShowMerge(false))
    setTimeout(() => {
      dispatch(setMergeProducts(null))
      dispatch(setMergeSpecification(null))
    }, 300)
  }, [bindUserCartMutate, dispatch, isShowMerge])

  const updateStatusCart = useCallback(
    ({ status }: { status: CART_STATUS }) => {
      if (!token) {
        return
      }
      updateStatusCartMutate({
        uid: token,
        status,
      })
    },
    [token, updateStatusCartMutate],
  )

  useEffect(() => {
    if (!isFetchingDataSource) {
      // проверяем все ли запросы закончились успешно, если что-то не загружено, сообщаем пользователю
      if (queriesDataSource.some((query) => query.isError)) {
        setErrorLoadingProducts(
          "Ошибка: часть товаров не была загружена. Обратитесь в тех. поддержку сайта",
        )
      }

      queriesDataSource.map((dataSource) => {
        // получаем данные товаров и
        // сохраняем в стейте текущие товары в корзине
        const dataProductsInCart = dataSource?.data
        if (!!dataProductsInCart) {
          dispatch(appendDataSource(dataProductsInCart))
        }
      })
    }
  }, [dispatch, isFetchingDataSource])

  // бэку нужно понимание - когда можно обновить цены у товаров для пользователя
  // если у пользователя идет процесс оформления заказа -> оплата и тд
  // цены не могут поменяться для него
  // в ином случае даем понять что товары могут менять свои цены
  // чтобы, привести ситуации закрытия страницы чекаута, любым способом, к минимуму,
  // и оставить параметр, запрещающий синхронизировать цены
  // делаем запрос каждый раз при монтировании страницы
  useEffect(() => {
    // если статус еще не получен
    // значит не было запроса корзины
    // значит нет смысла обновлять статус
    if (status === null) {
      return
    }

    const newStatus = ROUTES_ENABLE_STATUS_ORDERING.some((route) =>
      router.pathname.includes(route),
    )
      ? CART_STATUS.IN_PROCESS_ORDERING
      : CART_STATUS.IN_PROCESS_ADD_PRODUCTS

    // если текущий статус такой же
    // нет смысла отправлять запрос
    if (status === newStatus) {
      return
    }

    updateStatusCart({
      status: newStatus,
    })
  }, [router.pathname, status, updateStatusCart])

  useEffect(() => {
    if (isInitAuth) {
      if (isAuth) {
        void onAuthHandle()
      } else {
        onNotAuthHandle()
      }

      dispatch(setIsInit(true))
    }
  }, [isAuth, isInitAuth, dispatch])

  useEffect(() => {
    setIsFetching(isFetchingCart || isFetchingDataSource)
  }, [isFetchingCart, isFetchingDataSource])

  useEffect(() => {
    if (shippingDatesOrdered.length > 0) {
      dispatch(setNextShippingDate(shippingDatesOrdered[0]))
      dispatch(
        setMinShippingDate(
          shippingDatesOrdered[shippingDatesOrdered.length - 1],
        ),
      )
    } else {
      dispatch(setNextShippingDate(null))
      dispatch(setMinShippingDate(null))
    }
  }, [dispatch, shippingDatesOrdered])

  useEffect(() => {
    dispatch(setPickupDate(shipping.nextDate.iso))
  }, [dispatch, shipping.nextDate.iso])

  const contextValue = useMemo(
    () =>
      ({
        specification,
        updateSpecification: updateSpecificationDispatch,
        totalCost: computedTotalCost,
        token,
        updateToken,
        cartCount: cartCount,
        clearCart: clearCartHandler,
        shareCart: share,
        sharePath,
        isShowShare,
        setIsShowShare,
        multiAddToCart,
        isFetching,
        products,
        samples,
        updateProductPriceUnit: updateProductPriceUnitHandle,
        refetchCart,
        updateTotalCost: updateTotalCostDispatch,
        updateProductCost: updateProductCostDispatch,
        productsFetching,
        errorLoadingProducts,
        removeSpecification: removeSpecificationHandle,
        addProductInFetching: (uuid: string) => {
          dispatch(addProductsFetching(uuid))
        },
        removeProductInFetching: (uuid: string) => {
          dispatch(removeProductsFetching(uuid))
        },
        order: order,
        isEmpty,
        shipping: {
          cost: shipping.cost,
          free: shipping.free,
          nextDate: {
            iso: shipping.nextDate.iso,
            date: !!shipping.nextDate.iso
              ? new Date(shipping.nextDate.iso)
              : null,
          },
          minDate: {
            iso: shipping.minDate.iso,
            date: !!shipping.minDate.iso
              ? new Date(shipping.minDate.iso)
              : null,
          },
        },
        pickup: {
          iso: pickup.iso,
          date: !!pickup.iso ? new Date(pickup.iso) : null,
        },
        multiAddedToCart,
        merge: {
          products: productsMerge,
          specification: specificationMerge,
          isFetching: isFetchingMerge,
          isSuccess: isSuccessMerge,
          isShow: isShowMerge,
          isFetchingProducts: isFetchingProductsMerge,
          cancel: cancelMerge,
          accept: acceptMerge,
        },
        isShowToLogin,
        showToLogin,
        hideToLogin,
        productCost,
      } as CartContextType),
    [
      specification,
      updateSpecificationDispatch,
      computedTotalCost,
      token,
      updateToken,
      cartCount,
      clearCartHandler,
      share,
      sharePath,
      isShowShare,
      multiAddToCart,
      isFetching,
      products,
      samples,
      updateProductPriceUnitHandle,
      refetchCart,
      updateTotalCostDispatch,
      updateProductCostDispatch,
      productsFetching,
      removeSpecificationHandle,
      order,
      acceptMerge,
      isEmpty,
      shipping.cost,
      shipping.free,
      shipping.nextDate.iso,
      shipping.minDate.iso,
      pickup.iso,
      multiAddedToCart,
      productsMerge,
      specificationMerge,
      isFetchingMerge,
      isSuccessMerge,
      isShowMerge,
      isFetchingProductsMerge,
      cancelMerge,
      isShowToLogin,
      showToLogin,
      hideToLogin,
      productCost,
      dispatch,
      errorLoadingProducts,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}
