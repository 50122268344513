import { Fonts } from "@/components/Fonts";
import { META_TITLE_DEFAULT } from "@/components/Meta/constants";
import { Notifications } from "@/components/Notifications";
import { AuthGuard } from "@/hoc/AuthGuard";
import { Provider as AppProvider } from "@/hooks/app/app";
import { Provider as UserProvider } from "@/hooks/auth";
import { Provider as CartProvider } from "@/hooks/cart/context";
import { Provider as ComparesProvider } from "@/hooks/compares";
import { Provider as FavoritesProvider } from "@/hooks/favorites";
import { Provider as ModalsProvider } from "@/hooks/modals/context";
import { usePreserveScroll } from "@/hooks/preserveScroll";
import { Provider as SearchProvider } from "@/hooks/search/search";
import "@/styles/scss/globals.scss";
import { DefaultLayout } from "@/layouts/Default/DefaultLayout";
import { wrapper } from "@/store/store";
import { GlobalStyles } from "@/styles/utils/GlobalStyles";
import type { AppPropsWithLayout, PagePropsType } from "@/types";
import { reCaptchaKey } from "@/utils/constants";
import { categoriesServerProps } from "@/utils/serverProps/categoriesSSRProps";
import "nprogress/nprogress.css";
import { locationServerProps } from "@/utils/serverProps/locationSSRProps";
import { AppContext } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Router } from "next/router";
import NProgress from "nprogress"; //nprogress module
import { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider as ReduxProvider } from "react-redux";
import { Provider as ReakitIdProvider } from "reakit";
import RedirectsData from "../redirects_old_new.json";
const AppScripts = dynamic(() => import("@/components/AppScripts").then(m => m.AppScripts), {
  ssr: false
});
NProgress.configure({
  showSpinner: true,
  template: `<div class="bar" role="bar"><div class="peg"></div></div>
             <div class="spinner" role="spinner"><div class="spinner-box"><div class="spinner-icon"></div></div></div>`
});
Router.events.on("routeChangeStart", () => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
});
Router.events.on("routeChangeError", () => {
  NProgress.done();
});
function GrosterApp({
  Component,
  ...rest
}: AppPropsWithLayout): JSX.Element {
  const withLayout = Component.getLayout || (page => <DefaultLayout>{page}</DefaultLayout>);
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        retry: 1,
        retryDelay: 100,
        keepPreviousData: true
      },
      mutations: {
        retry: 1,
        retryDelay: 100
      }
    }
  }));
  const {
    store,
    props
  } = wrapper.useWrappedStore(rest);
  usePreserveScroll();
  return <>
      <GlobalStyles />
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>{META_TITLE_DEFAULT}</title>
        <Fonts />
      </Head>

      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={(props.pageProps as PagePropsType)?.dehydratedState}>
            <ReakitIdProvider>
              <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} scriptProps={{
              appendTo: "body",
              id: "reCaptchaG",
              async: true
            }}>
                <AppProvider>
                  <ModalsProvider>
                    <UserProvider>
                      <CartProvider>
                        <SearchProvider defaultValue={{
                        initialQuery: props.pageProps?.initQuery || undefined,
                        search: props.pageProps?.searchResult || undefined,
                        filterQuery: props.pageProps?.filterQuery || undefined
                      }}>
                          <FavoritesProvider>
                            <ComparesProvider>
                              {Component.requireAuth ? <AuthGuard>
                                  {withLayout(<Component {...props.pageProps} />)}
                                </AuthGuard> : <>
                                  {withLayout(<Component {...props.pageProps} />)}
                                </>}
                              <Notifications />
                            </ComparesProvider>
                          </FavoritesProvider>
                        </SearchProvider>
                      </CartProvider>
                    </UserProvider>
                  </ModalsProvider>
                </AppProvider>
              </GoogleReCaptchaProvider>
            </ReakitIdProvider>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
        <AppScripts />
      </ReduxProvider>
    </>;
}

// обертка getServerSideProps вокруг каждой страницы
// для отображения и получения геолокации и категорий
// не улучшила perfomance, но SSG из-за этого невозможно использовать везде
// в новом разделе blog и тп. потребуется использование ssg
// поэтому используем пока getInitialProps
GrosterApp.getInitialProps = wrapper.getInitialAppProps(store => async (appContext: AppContext) => {
  const {
    ctx,
    Component
  } = appContext;
  const {
    req,
    res,
    pathname,
    query,
    asPath
  } = ctx;
  const pathnamePage = (req?.url ?? "").split("?")[0] ?? "";
  const redirectData = (RedirectsData[pathnamePage] as undefined | {
    destination: string;
  });
  if (!!res && !!redirectData && pathnamePage !== redirectData.destination) {
    res.writeHead(301, {
      Location: redirectData.destination
    });
    res.end();
    return {
      pageProps: {}
    };
  }
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({
      ...appContext,
      pathname,
      query,
      asPath,
      store
    });
  }
  await Promise.all([locationServerProps(store)({
    res,
    req
  }), categoriesServerProps(store)()]);
  return {
    pageProps: pageProps
  };
});
export default GrosterApp;