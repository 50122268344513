import React from "react"
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader"

const Loader = () => {
  return (
    <BaseLoader
      isRelative
      message={"Загружаем..."}
      orientation={"row"}
      spinSize={"largeL"}
    />
  )
}

export { Loader }
