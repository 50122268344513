import { useQuery } from "react-query"
import { useDebounceValue, useWindowSize } from "usehooks-ts"
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { fetchAutoComplete, PAGE, SORT } from "@/api/digineticaAPI"
import {
  appendProducts,
  appendProductsData,
  PayloadDistinctType,
  PayloadSliderType,
  SearchHistoryItemType,
  setCategoriesAutoComplete,
  setCorrection,
  setCurrentQuery,
  setFacets,
  setFilter,
  setHistory,
  setIsNotResultAutoComplete,
  setIsShowAutoComplete,
  setIsShowFullScreenSearch,
  setIsUpdate,
  setPage,
  setProducts,
  setProductsAutoComplete,
  setProductsData,
  setQuery,
  setQueryAutoComplete,
  setSelectedFacets,
  setSort,
  setStsAutoComplete,
  setTapsAutoComplete,
  setTotal,
  setZeroQueries,
  updateFilter,
  UpdateFilterPropsType,
} from "@/store/reducers/searchSlice"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { scrollBodyDisable, scrollBodyEnable } from "@/utils/common/helpers"
import { ROUTES } from "@/utils/constants"
import { PRODUCT_FETCH_SIZE_MOBILE, PRODUCT_FETCH_SIZE_PC } from "./constants"
import {
  filterToQueryFormat,
  getCleanSearchQuery,
  getHistorySaved,
  setHistorySaved,
} from "./helpers"
import {
  ExtendSearchResultType,
  QueryPageSearchType,
  SearchContextProviderPropsType,
  UpdateSearchPropsType,
  UseSearchReturnContextType,
  UseSearchReturnType,
} from "./types"
import { setPopupIsShow as setIsShowCatalogPopup } from "../../store/reducers/catalogSlice"
import { useAppDispatch, useAppSelector } from "../redux"

const SearchContext = createContext<null | UseSearchReturnContextType>(null)

const Provider: FC<SearchContextProviderPropsType> = ({
  children,
  defaultValue,
}) => {
  const {
    history,
    query,
    autoComplete,
    correction,
    products,
    total,
    zeroQueries,
    facets,
    productsData,
    page,
    filter,
    selectedFacets,
    sort,
    isUpdate,
    fullScreen,
    currentQuery,
  } = useAppSelector(({ search }) => search)

  const { isShow: isShowAC, isNotResult: isNotResultAC } = autoComplete

  const defaultQuery = defaultValue?.initialQuery ?? null
  const defaultSearch = defaultValue?.search ?? null
  const defaultFilterQuery = defaultValue?.filterQuery ?? null

  const [debouncedCurrentQuery] = useDebounceValue(currentQuery, 200)

  const isUseCorrection = !!correction && !!query && correction !== query

  const isNotResults =
    products.length <= 0 &&
    (zeroQueries || (total !== null ? total <= 0 : false))

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { width } = useWindowSize()

  const dispatch = useAppDispatch()

  const router = useRouter()

  const productSizeAutoComplete =
    width !== undefined && width <= getBreakpointVal(breakpoints.md)
      ? PRODUCT_FETCH_SIZE_MOBILE
      : PRODUCT_FETCH_SIZE_PC

  const autoCompleteRef = useRef<HTMLDivElement | null>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const isAppendProducts = useRef<boolean>(false)

  const existFilterQty = useMemo(
    () => Object.keys(filter || {}).length,
    [filter],
  )

  const { isLoading: isLoadingAutoComplete, remove: removeAutoComplete } =
    useQuery(
      [
        "searchAutocomplete",
        productSizeAutoComplete,
        debouncedCurrentQuery,
        isShowAC,
        fullScreen.isShow,
      ],
      () =>
        fetchAutoComplete({
          query: debouncedCurrentQuery || "",
          size: productSizeAutoComplete,
        }),
      {
        enabled: isShowAC || fullScreen.isShow,
        onSuccess: (response) => {
          if (response === undefined) {
            return
          }
          if (response !== null) {
            dispatch(setQueryAutoComplete(response.query))
            dispatch(setProductsAutoComplete(response.products))
            dispatch(setCategoriesAutoComplete(response.categories))
            dispatch(
              setTapsAutoComplete(
                response.taps.length > 0 ? response.taps : [],
              ),
            )
            dispatch(
              setStsAutoComplete(response.sts.length > 0 ? response.sts : []),
            )

            dispatch(
              setIsNotResultAutoComplete(
                !!response.query &&
                  !response.products.length &&
                  !response.sts.length &&
                  !response.taps.length,
              ),
            )
          } else {
            dispatch(setQueryAutoComplete(null))
            dispatch(setProductsAutoComplete([]))
            dispatch(setCategoriesAutoComplete([]))
            dispatch(setTapsAutoComplete([]))
            dispatch(setStsAutoComplete([]))
          }
        },
      },
    )

  const updateCurrentQuery = useCallback(
    (value: string) => {
      dispatch(setCurrentQuery(getCleanSearchQuery({ query: value })))
    },
    [dispatch],
  )

  const inputFocus = useCallback(() => {
    if (!inputRef.current) {
      document.getElementById("id-search")?.focus()
    } else {
      inputRef.current?.focus()
    }
  }, [])

  const inputBlur = useCallback(() => {
    if (!inputRef.current) {
      document.getElementById("id-search")?.blur()
    } else {
      inputRef.current?.blur()
    }
  }, [])

  const showAutoComplete = useCallback(() => {
    inputFocus()
    dispatch(setIsShowCatalogPopup(false))
    dispatch(setIsShowAutoComplete(true))
    scrollBodyDisable()
  }, [dispatch, inputFocus])

  const hideAutoComplete = useCallback(
    ({
      _isUpdateQueryText = false,
    }: { _isUpdateQueryText?: boolean } | undefined = {}) => {
      dispatch(setIsShowAutoComplete(false))
      dispatch(setIsShowFullScreenSearch(false))

      scrollBodyEnable()

      inputBlur()

      if (!!_isUpdateQueryText) {
        if (query !== currentQuery && !isSubmitting) {
          updateCurrentQuery(query || "")
        }
      }
    },
    [dispatch, query, currentQuery, isSubmitting, updateCurrentQuery],
  )

  const setSearchResult = useCallback(
    (search: ExtendSearchResultType | null) => {
      if (!search) {
        dispatch(setCorrection(null))
        dispatch(setProducts([]))
        dispatch(setProductsData([]))
        dispatch(setTotal(0))
        dispatch(setZeroQueries(false))
        dispatch(setQuery(""))
        dispatch(setFacets([]))
        dispatch(setSelectedFacets([]))
        dispatch(setPage(PAGE))
        dispatch(setSort(SORT))
        return
      }

      const {
        facets,
        selectedFacets,
        page,
        sortQuery,
        zeroQueries,
        query,
        products,
        correction,
        productsData,
        totalHits,
      } = search

      dispatch(setCorrection(correction || null))
      if (isAppendProducts.current) {
        dispatch(appendProducts(products || []))
        dispatch(appendProductsData(productsData || []))
      } else {
        dispatch(setProducts(products || []))
        dispatch(setProductsData(productsData || []))
      }
      dispatch(setTotal(totalHits || 0))
      dispatch(setZeroQueries(Boolean(zeroQueries)))
      dispatch(setQuery(query || ""))
      dispatch(setFacets(facets || []))
      dispatch(setSelectedFacets(selectedFacets || []))
      dispatch(setPage(!!page ? +page : PAGE))
      dispatch(setSort(!!sortQuery ? sortQuery : SORT))
    },
    [dispatch],
  )

  const setSearchFilter = useCallback(
    (filterQuery: string[] | null) => {
      dispatch(setIsUpdate(false))

      if (filterQuery !== null) {
        const buildFilter: Record<string, string[]> = {}
        filterQuery.map((f) => {
          const item = f.split(":")
          const name = item[0]
          const values = item[1]
          if (!name || !values) {
            return
          }

          buildFilter[name] = values.split(";")
        })
        dispatch(setFilter(buildFilter))
      } else {
        dispatch(setFilter(null))
      }
    },
    [dispatch],
  )

  const clearSearchResult = useCallback(() => {
    removeAutoComplete()
    setSearchResult(null)
    updateCurrentQuery("")
    dispatch(setIsUpdate(false))
  }, [updateCurrentQuery, setSearchResult, dispatch, removeAutoComplete])

  const clearAutoComplete = useCallback(() => {
    updateCurrentQuery("")
    inputFocus()
  }, [updateCurrentQuery, inputFocus])

  const updateFilterHandle = useCallback(
    (props: UpdateFilterPropsType<PayloadDistinctType | PayloadSliderType>) => {
      dispatch(updateFilter(props))
      dispatch(setPage(PAGE))
      dispatch(setIsUpdate(true))
    },
    [dispatch],
  )

  const resetFilterHandle = useCallback(() => {
    dispatch(setFilter({}))
    dispatch(setPage(PAGE))
    dispatch(setIsUpdate(true))
  }, [dispatch])

  const updateSearch = useCallback(
    ({
      page: pageIn,
      filter: filterIn,
      query: queryIn,
      sort: sortIn,
      withReset = false,
      isAppend = false,
    }: UpdateSearchPropsType) => {
      isAppendProducts.current = isAppend
      let buildQuery: QueryPageSearchType

      if (queryIn !== undefined && withReset) {
        buildQuery = {
          query: queryIn,
        }
      } else {
        const p =
          pageIn !== undefined ? pageIn : page !== null ? page : undefined
        const s =
          sortIn !== undefined ? sortIn : sort !== null ? sort : undefined
        const f = filterToQueryFormat(
          filterIn !== undefined ? filterIn : filter,
        )
        buildQuery = {
          filter: f,
          query: queryIn || query || undefined,
          page: p !== undefined && p !== PAGE ? `${p}` : undefined,
          sort: s !== undefined && s !== SORT ? s : undefined,
        }
      }

      const cleanQuery = Object.fromEntries(
        Object.entries(buildQuery).filter(([, value]) => value !== undefined),
      )

      void router
        .replace(
          {
            pathname: `${ROUTES.search}`,
            query: cleanQuery,
          },
          undefined,
          {
            scroll: !isAppend || !isAppendProducts.current,
            shallow: false,
          },
        )
        .catch((err) => console.log("error: ", err))
        .then(() => console.log("this will succeed"))
    },
    [page, sort, filter, query],
  )

  const appendHistoryItem = useCallback(
    (item: SearchHistoryItemType) => {
      const h = [...history, item].reduce(
        (uniq: SearchHistoryItemType[], item) => {
          return !!uniq.find((el) => el.query === item.query)
            ? uniq
            : [...uniq, item]
        },
        [],
      )

      dispatch(setHistory(h))
      setHistorySaved(h)
    },
    [dispatch, history],
  )

  const removeHistoryItem = useCallback(
    (item: SearchHistoryItemType) => {
      const h = history.filter((el) => el.query !== item.query)
      dispatch(setHistory(h))
      setHistorySaved(h)
    },
    [dispatch, history],
  )

  const clearHistory = useCallback(() => {
    dispatch(setHistory([]))
    setHistorySaved(null)
  }, [dispatch])

  useEffect(() => {
    if (defaultQuery === null) {
      return
    }
    const query = defaultQuery || ""
    updateCurrentQuery(query)
    dispatch(setQueryAutoComplete(query))
  }, [defaultQuery, dispatch, updateCurrentQuery])

  useEffect(() => {
    setSearchResult(defaultSearch)
  }, [defaultSearch, setSearchResult])

  useEffect(() => {
    setSearchFilter(defaultFilterQuery)
  }, [defaultFilterQuery, setSearchFilter])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        hideAutoComplete()
      }
    }

    if (isShowAC) {
      document.addEventListener("keydown", handleKeyDown)
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isShowAC]) // Подписываемся, только если автокомплит открыт

  useEffect(() => {
    if (isShowAC) {
      showAutoComplete()
    }
  }, [isShowAC])

  useEffect(() => {
    router.events.on("routeChangeComplete", hideAutoComplete)

    return () => {
      router.events.off("routeChangeComplete", hideAutoComplete)
    }
  }, [hideAutoComplete, router.events])

  useEffect(() => {
    if (filter !== null && isUpdate) {
      updateSearch({
        filter: filter,
      })
    }
  }, [filter, updateSearch, isUpdate])

  useEffect(() => {
    dispatch(setHistory(getHistorySaved()))
  }, [dispatch])

  const contextValue = useMemo(
    () =>
      ({
        query: query,
        currentQuery,
        updateQueryText: updateCurrentQuery,
        appendToHistory: appendHistoryItem,
        removeFromHistory: removeHistoryItem,
        history: history,
        clearHistory,
        correction: correction,
        isUseCorrection,
        setSearchResult,
        products: products,
        total: total,
        zeroQueries,
        autoComplete: {
          products: autoComplete.products,
          brands: autoComplete.brands,
          taps: autoComplete.taps,
          sts: autoComplete.sts,
          categories: autoComplete.categories,
          query: autoComplete.query,
          contents: autoComplete.contents,
          ref: autoCompleteRef,
          isNotResult: isNotResultAC,
          isShow: isShowAC,
          isLoading: isLoadingAutoComplete,
          hide: hideAutoComplete,
          show: showAutoComplete,
        },
        isNotResults,
        facets,
        productsData,
        clearSearchResult,
        clearAutoComplete,
        page,
        setSearchFilter,
        updateSearch,
        updateFilter: updateFilterHandle,
        filter,
        selectedFacets,
        resetFilter: resetFilterHandle,
        setIsSubmitting,
        isSubmitting,
        sort,
        fullScreen,
        inputRef,
        existFilterQty,
        inputFocus,
      } as const as UseSearchReturnContextType),
    [
      query,
      currentQuery,
      updateCurrentQuery,
      appendHistoryItem,
      removeHistoryItem,
      history,
      clearHistory,
      correction,
      isUseCorrection,
      setSearchResult,
      products,
      total,
      zeroQueries,
      autoComplete.products,
      autoComplete.brands,
      autoComplete.taps,
      autoComplete.sts,
      autoComplete.categories,
      autoComplete.query,
      autoComplete.contents,
      isNotResultAC,
      isShowAC,
      isLoadingAutoComplete,
      hideAutoComplete,
      showAutoComplete,
      isNotResults,
      facets,
      productsData,
      clearSearchResult,
      clearAutoComplete,
      page,
      setSearchFilter,
      updateSearch,
      updateFilterHandle,
      filter,
      selectedFacets,
      resetFilterHandle,
      isSubmitting,
      sort,
      fullScreen,
      existFilterQty,
      inputFocus,
    ],
  )

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

const useSearch = (): UseSearchReturnType => {
  const searchContext = useContext(SearchContext)

  if (searchContext === null) {
    throw new Error("Search context have to be provided")
  }

  return searchContext
}

export { SearchContext, Provider, useSearch }
