import { cx } from "@linaria/core";
import React, { FC, memo, MutableRefObject, useCallback, useEffect, useState } from "react";
import { TagRectification } from "@/components/Tags/TagRectification";
import { useAppSelector } from "@/hooks/redux";
import { useSearch } from "@/hooks/search/search";
import { cssIsActive } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { Icon } from "@/ui/Icon";
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader";
import { Typography } from "@/ui/Typography/Typography";
import { Category } from "./Category";
import { History } from "./History";
import { StyledProducts } from "./Products/Product/StyledProduct";
import { Products } from "./Products/Products";
import { cssCategories, cssSts, cssTaps, StyledAutoComplete, StyledResult, StyledResultItem, StyledResultItemInner, StyledResultList, StyledResultListArea, StyledResultTitle } from "./StyledAutoComplete";
import { StyledTags, StyledTagsSection } from "../../Tags/StyledTags";
export type AutoCompletePropsType = {
  fieldRef?: MutableRefObject<HTMLDivElement | null>;
  selectQuery?: (query: string) => void;
  showAllResults?: () => void;
};
const AutoComplete: FC<AutoCompletePropsType> = memo(({
  selectQuery,
  showAllResults,
  ...props
}) => {
  const {
    history,
    removeFromHistory,
    clearHistory,
    autoComplete: {
      isShow,
      products,
      taps,
      sts,
      categories,
      query,
      isNotResult,
      isLoading
    },
    updateSearch,
    currentQuery
  } = useSearch();
  const selectItem = useCallback((query: string) => {
    if (selectQuery) {
      selectQuery(query);
    }
  }, [selectQuery]);
  const queryIsExist = query !== null && query.length > 0;
  // показываем товары только
  // если ПК версия
  // или нет подсказок и совпадений от дигинетики
  const isShowProducts = true;
  const updateCategorySearch = useCallback(({
    title
  }: {
    cId: string;
    title: string;
  }) => {
    updateSearch({
      query: title,
      withReset: true
    });
  }, [updateSearch]);
  return <StyledAutoComplete {...props} className={cx(isShow && cssIsActive)}>
        {isLoading && <BaseLoader />}

        <StyledResultListArea>
          {taps.length > 0 && <StyledResult className={cssTaps}>
              <StyledTagsSection>
                <StyledTags>
                  {taps.map((t, i) => <TagRectification key={i} onClick={() => updateSearch({
              query: t.relatedSearch,
              withReset: true
            })}>
                      {t.relatedSearch}
                    </TagRectification>)}
                </StyledTags>
              </StyledTagsSection>
            </StyledResult>}

          <History removeFromHistory={removeFromHistory} clearHistory={clearHistory} query={isNotResult ? "" : currentQuery ?? undefined} history={history} selectItem={selectItem} taps={taps} />

          {sts.length > 0 && <StyledResult className={cssSts}>
              <StyledResultTitle>Часто ищут</StyledResultTitle>
              <StyledResultList>
                {sts.filter((_, i) => i < 3).map((item, i) => <StyledResultItem key={i}>
                      <StyledResultItemInner onClick={() => {
              selectItem(item.st);
            }}>
                        <Icon NameIcon={"Search"} size={"mediumM"} />
                        <Typography variant={"p14"}>{item.st}</Typography>
                      </StyledResultItemInner>
                    </StyledResultItem>)}
              </StyledResultList>
            </StyledResult>}

          {!!categories.length && queryIsExist && <StyledResult className={cssCategories}>
              <StyledResultTitle>Категории</StyledResultTitle>
              <StyledProducts>
                {categories.map(c => <Category category={c} key={c.id} updateCategorySearch={updateCategorySearch} />)}
              </StyledProducts>
            </StyledResult>}
        </StyledResultListArea>

        {isShowProducts && <Products products={products}>
            {queryIsExist && <Button variant={"translucent"} size={"small"} onClick={() => {
        if (showAllResults) {
          showAllResults();
        }
      }}>
                Смотреть все результаты
              </Button>}
          </Products>}
      </StyledAutoComplete>;
});
const AutoCompleteWrapper: FC<AutoCompletePropsType> = props => {
  const defaultIsShow = useAppSelector(state => state.search.autoComplete.isShow);
  const [isInit, setIsInit] = useState(defaultIsShow);
  useEffect(() => {
    if (defaultIsShow) {
      setIsInit(defaultIsShow);
    }
  }, [defaultIsShow]);
  return <>{isInit && <AutoComplete {...props} />}</>;
};
AutoComplete.displayName = "AutoComplete";
export { AutoCompleteWrapper as AutoComplete };