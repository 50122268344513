import React from "react"
import { GLOBAL_QUESTION } from "@/components/FAQ/constants"
import { SITE_URL, TITLE_SITE_RU } from "@/utils/constants"

export const QaJsonLd = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "QAPage",
          mainEntity: {
            "@type": "Question",
            name: GLOBAL_QUESTION.title,
            text: GLOBAL_QUESTION.title,
            answerCount: 1,
            acceptedAnswer: {
              "@type": "Answer",
              text: GLOBAL_QUESTION.answer,
              author: {
                "@type": "Organization",
                name: TITLE_SITE_RU,
              },
              datePublished: "2025-03-20T12:10:00+03:00",
              upvoteCount: 32,
              url: `${SITE_URL}/faq`,
            },
          },
        }),
      }}
    />
  )
}
